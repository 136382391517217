body {
	margin: 0;
	font-family: 'Open Sans';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root,
html,
body {
	height: 100vh;
	margin: 0;
}
* {
	box-sizing: border-box;
}

#root {
	display: flex;
}

/* Scroll-bar */
::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	background: #F6F7F7;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background: #CCCFCE; 
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background: #989F9D; 
}